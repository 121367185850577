import React from "react";

import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { CalendarEventStatus } from "fieldpro-tools";
import _ from "lodash";
import moment from "moment";
import { Draggable, Droppable } from "react-beautiful-dnd";

import DynamicallyRenderedMenuList from "components/Map/DynamicalyRenderedMenuList";

import { IBoardProps } from "./Board";
import { TCalendarEventFE } from "./redux/types";
import StackedBoardItem from "./StackedBoardItem";
import { boardItemStyles, stackedBoardStyles } from "./styles";
import { computeEventsIndexes } from "./utils/computeEventsIndexes";

export interface IStackBoardProps
  extends Pick<
    IBoardProps,
    | "onClickApproveEvent"
    | "onClickDeclineEvent"
    | "onClickDeleteEvent"
    | "onClickTimeSlot"
    | "onEditEvent"
    | "boardId"
    | "events"
    | "activeBoardItems"
    | "eventPlaceHolders"
  > {
  minSlotCount: number;
  onClickOnBoardItem?: (
    e: React.MouseEvent<HTMLDivElement>,
    event: TCalendarEventFE
  ) => void;
  classes: ClassNameMap<keyof typeof stackedBoardStyles>;
  boardItemClasses: ClassNameMap<keyof typeof boardItemStyles>;
}

const StackedBoard: React.FC<IStackBoardProps> = ({
  onClickTimeSlot,
  onClickOnBoardItem,
  eventPlaceHolders,
  activeBoardItems,
  boardId,
  events: _events,
  minSlotCount,
  classes,
  boardItemClasses,
}) => {
  const events = computeEventsIndexes(_events);

  function handleClickTimeSlot(boardId: string) {
    onClickTimeSlot && onClickTimeSlot(moment(boardId));
  }

  function buildMenuItemFunction(index: number) {
    const event = events[index];

    if (!event) {
      return null;
    }

    return (
      <Draggable
        key={String(event.id) + index}
        draggableId={event.id as string}
        index={index}
      >
        {(provided) => (
          <div
            key={String(event.id)}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <div key={event.id} className={classes.boardItem}>
              <div className={classes.boardItemInner}>
                <StackedBoardItem
                  event={event}
                  active={_.includes(activeBoardItems, event.id)}
                  classes={boardItemClasses}
                  headingText={`Stop ${index + 1}`}
                  onClickOnBoardItem={onClickOnBoardItem}
                />
              </div>
            </div>
          </div>
        )}
      </Draggable>
    );
  }

  return (
    <Droppable droppableId={boardId} key={boardId}>
      {(provided) => (
        <div
          className={classes.stackedBoardcontainer}
          ref={provided.innerRef}
          {...provided.droppableProps}
          data-testid={`board-${boardId}`}
        >
          {_.map(_.slice(events, 0, 20), (_, index) => {
            return buildMenuItemFunction(index);
          })}

          <DynamicallyRenderedMenuList
            Component={React.Fragment}
            maxItems={20}
            listSize={events.length}
            buildMenuItemFunction={(index) => {
              return buildMenuItemFunction(index + 20);
            }}
          />

          {provided.placeholder}

          {_.map(eventPlaceHolders, (event, index) => {
            return (
              <div
                key={index}
                className={classes.boardItem}
                style={{
                  opacity: 0.7,
                }}
              >
                <div className={classes.boardItemInner}>
                  <StackedBoardItem
                    event={{
                      status: CalendarEventStatus.ONGOING,
                      ...event,
                    }}
                    classes={boardItemClasses}
                  />
                </div>
              </div>
            );
          })}

          <DynamicallyRenderedMenuList
            Component={React.Fragment}
            maxItems={10}
            listSize={minSlotCount}
            buildMenuItemFunction={(index) => {
              return (
                <div
                  key={index}
                  className={classes.slot}
                  onClick={() => handleClickTimeSlot(boardId)}
                >
                  <div className={classes.slotInner}></div>
                </div>
              );
            }}
          />
        </div>
      )}
    </Droppable>
  );
};

export default StackedBoard;
