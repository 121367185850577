import React from "react";

import { makeStyles } from "@material-ui/core";
import { TCalendarEvent } from "fieldpro-tools";
import _ from "lodash";
import { DragDropContext, OnDragEndResponder } from "react-beautiful-dnd";
import { useSelector } from "react-redux";

import {
  GreyDark,
  GreyLight,
  secondaryColor,
  StrokeLowEnphasis,
} from "assets/colors";
import { getSelectedClient } from "containers/clients/redux/selectors";
import { useMomentTimeZone } from "hooks/useMomentTimeZone";

import { IBoardProps, TEventPlaceHolder } from "../Board";
import BoardItemMenu from "../BoardItemMenu";
import { TCalendarEventFE } from "../redux/types";
import StackedBoard from "../StackedBoard";
import { boardItemStyles, stackedBoardStyles } from "../styles";
import {
  BOX_HEIGHT,
  getCalendarNumberOfDays,
  ICalendarProps,
} from "../WeeklyCalendar";

interface ICalendarStylesProps {
  width: string;
  height: string | number;
  daysCount: number;
  minWidth?: string;
  maxWidth?: string;
  draggable?: boolean;
}

const useStyles = makeStyles(() => ({
  container: ({
    width,
    maxWidth,
    minWidth,
    height,
    daysCount,
  }: ICalendarStylesProps) => ({
    height,
    overflowY: "scroll",
    overflowX: "hidden",
    width: width,
    background: "white",
    display: "grid",
    gridTemplateColumns: `repeat(${daysCount},1fr)`,
    gridcolumnGap: "0px",
    boxShadow: `inset 0px 5px 3px -3px ${GreyLight}`,
    paddingTop: "0.2px",
    maxWidth,
    minWidth,
  }),
  boardContainer: ({
    width,
    daysCount,
    minWidth,
    maxWidth,
  }: ICalendarStylesProps) => ({
    width: `calc(${width} / ${daysCount})`,
    minWidth: minWidth,
    maxWidth: maxWidth,
  }),
  dialogHeader: ({ width }: ICalendarStylesProps) => ({
    boxShadow: "0px 1px 3px 0px rgba(18, 78, 93, 0.15)",
    background: "white",
    width: `calc(${width} - 8px)`,
    display: "grid",
  }),
  box: {
    borderBottom: `2px solid ${StrokeLowEnphasis}`,
    height: `${BOX_HEIGHT}px`,
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
    background: "white",
    color: GreyDark,
    fontSize: "13px",
    paddingTop: "8px",
  },
}));

//Styling a component seems surprisingly costly in performance
//so we take these out of the loops to avoid repeatedly making the same styles over and over
const useStackedBoardStyles = makeStyles(stackedBoardStyles);
const useBoardItemStyles = makeStyles(boardItemStyles);

export interface IDayOnlyCalendarProps
  extends Pick<
      ICalendarProps,
      "width" | "minWidth" | "maxWidth" | "height" | "draggable" | "onChange"
    >,
    Pick<IBoardProps, "activeBoardItems" | "onClickTimeSlot"> {
  dates: string[];
  events: Array<Array<TCalendarEvent>>;
  placeholders: Array<Array<TEventPlaceHolder>>;
  minSlotCount: number;
  onEditEvent: (event: TCalendarEventFE) => void;
  onClickDelete: (event: TCalendarEventFE) => void;
  onClickApproveEvent?: (event: TCalendarEventFE) => void;
  onClickDeclineEvent?: (event: TCalendarEventFE) => void;
  onClickDeleteEvent: (event: TCalendarEventFE) => void;
  handleDragEnd: OnDragEndResponder;
}
const WeeklyCalendarDayOnlyView: React.FC<IDayOnlyCalendarProps> = ({
  onClickApproveEvent,
  onClickDeclineEvent,
  onClickDeleteEvent,
  onClickTimeSlot,
  activeBoardItems,
  onEditEvent,
  maxWidth,
  minWidth,
  width = "auto",
  draggable,
  height = "calc(100vh - 230px)",
  dates,
  events,
  minSlotCount: minSlotCount,
  handleDragEnd,
  placeholders,
}) => {
  const client = useSelector(getSelectedClient);
  const { moment, timeZone } = useMomentTimeZone();
  const DAYS_COUNT = getCalendarNumberOfDays(client);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedEvent, setSelectedEvent] =
    React.useState<TCalendarEvent | null>();

  const classes = useStyles({
    width,
    daysCount: DAYS_COUNT,
    minWidth,
    maxWidth,
    draggable,
    height,
  });
  const stackedBoardClasses = useStackedBoardStyles();
  const boardItemClasses = useBoardItemStyles();

  const onClickOnBoardItem = (
    e: React.MouseEvent<HTMLDivElement>,
    event: TCalendarEventFE
  ) => {
    setAnchorEl(e.currentTarget);
    setSelectedEvent(event);
  };

  return (
    <div>
      <div
        className={classes.dialogHeader}
        key={JSON.stringify(dates) + timeZone}
      >
        <div>{/* Empty space */}</div>
        <div
          style={{
            display: "grid",
            gridAutoFlow: "column",
            gridTemplateColumns: `repeat(${DAYS_COUNT}, calc((${width} / ${DAYS_COUNT}) - 2px))`,
            boxShadow: "0px -7px 9px 1px grey",
          }}
        >
          {_.map(dates, (date) => (
            <div
              key={date}
              style={{
                textAlign: "center",
                paddingTop: "6px",
                paddingBottom: "6px",
                fontWeight: 500,
                color: moment().isSame(date, "day")
                  ? secondaryColor
                  : undefined,
                borderBottom: moment().isSame(date, "day")
                  ? "2px solid"
                  : undefined,
              }}
            >
              {moment(date).format("ddd D")}
            </div>
          ))}
        </div>
      </div>

      <DragDropContext onDragEnd={handleDragEnd}>
        <div className={classes.container} key={DAYS_COUNT}>
          {_.map(dates, (date, index) => {
            const matchingEvents = events[index];
            const matchingPlaceholders = placeholders[index];
            return (
              <StackedBoard
                key={date}
                onClickDeleteEvent={onClickDeleteEvent}
                onClickApproveEvent={onClickApproveEvent}
                onClickDeclineEvent={onClickDeclineEvent}
                onClickTimeSlot={onClickTimeSlot}
                onEditEvent={onEditEvent}
                boardId={date.toString()}
                events={matchingEvents}
                activeBoardItems={activeBoardItems}
                eventPlaceHolders={matchingPlaceholders}
                minSlotCount={minSlotCount}
                onClickOnBoardItem={onClickOnBoardItem}
                classes={stackedBoardClasses}
                boardItemClasses={boardItemClasses}
              />
            );
          })}
        </div>
      </DragDropContext>
      <BoardItemMenu
        anchorEl={anchorEl}
        event={selectedEvent as TCalendarEvent}
        handleClickApprove={() => {
          onClickApproveEvent &&
            onClickApproveEvent(selectedEvent as TCalendarEvent);
          setAnchorEl(null);
        }}
        handleClickDecline={() => {
          onClickDeclineEvent &&
            onClickDeclineEvent(selectedEvent as TCalendarEvent);
          setAnchorEl(null);
        }}
        handleClickDelete={() => {
          onClickDeleteEvent &&
            onClickDeleteEvent(selectedEvent as TCalendarEvent);
          setAnchorEl(null);
        }}
        handleClickEdit={() => {
          onEditEvent(selectedEvent as TCalendarEvent);
          setAnchorEl(null);
        }}
        handleClose={() => setAnchorEl(null)}
        id={"board-item-menu"}
        isManager={false}
        open={Boolean(anchorEl)}
      />
    </div>
  );
};

export default WeeklyCalendarDayOnlyView;
